import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import _ from 'lodash';
import { Category } from '@wix/ambassador-category-services/types';
import { GeoData } from '@wix/wix-common-aspects';
import {
  ClientProject,
  Language,
  UserDetails,
  LocaleDataTranslations,
  PartnerProfileEnriched,
} from '@common/types';
import { DEFAULT_CURRENCY } from '@utils/constants';

interface ProfileContextProviderProps {
  children?: ReactNode;
  partnerProfile: PartnerProfileEnriched;
  languages?: Array<Language>;
  language: string;
  services?: Array<Category>;
  geo?: GeoData;
  userDetails?: UserDetails;
  origin?: string;
  host: string;
  entry?: string;
  isMyProfile?: boolean;
  enabled?: boolean;
  error?: string;
  isRenderedInModal?: boolean;
  localeDataTranslations?: LocaleDataTranslations;
  isMobileUserAgent?: boolean;
  userCurrency?: string;
  preferredCurrency?: string;
  currencyConversionRate?: number;
}

type State = {
  appStatus: string;
  partnerProfile: PartnerProfileEnriched;
  languages?: Array<Language>;
  language: string;
  services?: Array<Category>;
  offeredServices?: Array<any>;
  geo?: GeoData;
  userCurrency: string;
  preferredCurrency?: string;
  currencyConversionRate: number;
  localeDataTranslations: LocaleDataTranslations;
  userDetails?: UserDetails;
  origin?: string;
  host: string;
  entry?: string;
  isMyProfile?: boolean;
  enabled: boolean;
  isRenderedInModal?: boolean;
  isMobileUserAgent?: boolean;
};

interface ProfileContextType extends State {
  dispatch: (arg: any) => void;
}

const ProfileContext = createContext<ProfileContextType | null>(null);

function mainReducer(state: State, action: any) {
  switch (action.type) {
    case ACTIONS.SET_OFFERED_SERVICES: {
      const { offeredServices } = action;
      return {
        ...state,
        offeredServices,
      };
    }
    case ACTIONS.SET_PROJECTS: {
      const { projects, projectsCursor } = action;
      const _partnerProfile = _.cloneDeep(state.partnerProfile);
      _.set(_partnerProfile, 'projects', [
        ..._.get(state.partnerProfile, 'projects'),
        ...projects,
      ]);
      _.set(_partnerProfile, 'projectsCursor', projectsCursor);
      return {
        ...state,
        partnerProfile: _partnerProfile,
      };
    }
    case ACTIONS.RESET_PROJECTS: {
      const { projects } = action;
      const _partnerProfile = _.cloneDeep(state.partnerProfile);
      _.set(_partnerProfile, 'projects', [...projects]);
      return {
        ...state,
        partnerProfile: _partnerProfile,
      };
    }
    case ACTIONS.UPDATE_PROJECT: {
      const { project } = action;
      const _partnerProfile = _.cloneDeep(state.partnerProfile);
      const projects = _.get(state.partnerProfile, 'projects');
      const projectIndex = projects.findIndex(
        (p: ClientProject) => p.id === project.id,
      );
      projects[projectIndex] = project;
      _.set(_partnerProfile, 'projects', projects);
      return {
        ...state,
        partnerProfile: _partnerProfile,
      };
    }
    default:
      throw new Error();
  }
}

export const ACTIONS = {
  SET_PROJECTS: 'SET_PROJECTS',
  RESET_PROJECTS: 'RESET_PROJECTS',
  SET_OFFERED_SERVICES: 'SET_OFFERED_SERVICES',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
};

export function ProfileContextProvider(props: ProfileContextProviderProps) {
  useEffect(() => {
    const offeredServiceIds: Record<string, { id: string; minPrice: number }> =
      {};
    _.forEach(
      _.cloneDeep(props.partnerProfile?.services?.values),
      (service) => {
        offeredServiceIds[service.id!] = {
          id: service.id!,
          minPrice: service.minPrice!,
        };
      },
    );

    const offeredServicesHierarchyBuilder = _.map(
      props.services,
      (category) => {
        const offeredServicesWithMinPrice = _.map(
          category.services,
          (service) => {
            if (_.includes(_.keys(offeredServiceIds), service.id)) {
              return {
                ...service,
                minPrice: offeredServiceIds?.[service.id!].minPrice,
              };
            }
          },
        );

        const filteredOfferedServices = _.filter(
          offeredServicesWithMinPrice,
          (el) => {
            return !_.isUndefined(el);
          },
        );

        if (_.size(filteredOfferedServices) > 0) {
          return { ...category, services: filteredOfferedServices };
        }
        return undefined;
      },
    );

    const offeredServicesHierarchy = _.filter(
      offeredServicesHierarchyBuilder,
      (category) => {
        return !_.isUndefined(category);
      },
    );

    dispatch({
      type: ACTIONS.SET_OFFERED_SERVICES,
      offeredServices: offeredServicesHierarchy,
    });
  }, [props.partnerProfile, props.services]);

  const [state, dispatch] = useReducer(mainReducer, {
    appStatus: 'initialized',
    partnerProfile: props.partnerProfile,
    geo: props.geo,
    languages: props.languages,
    language: props.language || 'en',
    services: props.services,
    userDetails: props.userDetails,
    offeredServices: [],
    origin: props.origin,
    host: props.host,
    entry: props.entry,
    isMyProfile: props.isMyProfile,
    enabled: Boolean(props.enabled),
    isRenderedInModal: props.isRenderedInModal,
    userCurrency: props?.userCurrency ?? DEFAULT_CURRENCY,
    preferredCurrency: props?.preferredCurrency,
    currencyConversionRate: props?.currencyConversionRate ?? 1,
    isMobileUserAgent: props.isMobileUserAgent,
    localeDataTranslations: props?.localeDataTranslations || {},
  });

  return (
    <ProfileContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
}

export function useProfileContext() {
  const appContext = useContext(ProfileContext);
  if (!appContext) {
    throw new Error(
      'useProfileContext must be used within the ProfileContext.Provider',
    );
  }
  return appContext;
}
